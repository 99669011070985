.masthead {
  padding: $space;
  text-align: center;

  &__title {
    color: hsl(238.2, 51.8%, 37.5%);
    font-family: $font-stack-script;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.1;
    margin: 0;
  }

  &__description {
    color: hsl(238.2, 51.8%, 37.5%);
    font-family: $font-stack-secondary;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.1;
    margin: 0;
    text-transform: uppercase;

    @include mq-600 {
    font-size: 2.5rem;
    }
  }
}