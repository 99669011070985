@media (max-width: 400px) {
  .headerImg {
    max-width: 400px;
    width: auto;
  }
  .footerImg {
    max-width: 400px;
    width: auto;
  }
  .caption {
    font-size: 10px;
    margin-left: 1mm;
    max-width: 60ch;
  }
}

@media (min-width: 401px) and (max-width: 1198px) {
  .headerImg {
    max-width: 700px;
    width: auto;
  }
  .footerImg {
    max-width: 700px;
    width: auto;
  }
  /* delete caption color */
  .caption {
    margin-left: 9mm;
    max-width: 70ch;
    font-size: 15px;
  }
}

@media (min-width: 1198px) {
  img {
    max-width: 1199px;
    width: auto;
  }

  .headerImg {
    margin-left: 4px;
  }

  .footerImg {
    margin-top: 4em;
  }
  .caption {
    font-size: 20px;
    margin-left: 23mm;
    max-width: 85ch;
  }
}

.printWarningBanner {
  animation-name: printableWarning;
  animation-duration: 10s;
  animation-timing-function: ease-out;
  animation-fill-mode: forward;
  width: 70%;
  padding: 20px;
  visibility: hidden;
  background-color: transparent;
  color: transparent;
  position: absolute;
}

@keyframes printableWarning {
  0% {
    visibility: visible;
    background-color: blue;
    color: white;
  }

  100% {
    visibility: visible;
    background-color: transparent;
    color: transparent;
  }
}
