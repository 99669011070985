label {
	display: block;
	font-size: 0.75em;
	text-transform: capitalize;

  + * {
  	margin-top: 0.25rem;
  }

  [type="radio"] + & {
  	padding-left: 0.25em;
  }
}