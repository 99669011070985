.form {
  border: 0.5em solid $black;
  margin: 0;
  padding: $space;

  &__fieldset {

    @include mq-600 {
      display: flex;
      display: grid;
      flex-wrap: wrap;
      grid-gap: $space;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__row {
    margin-bottom: $space;

    @include mq-600 {
      flex: 1 0 calc(50% - $space);

      @supports(display: grid) {
        margin-bottom: 0;
      }
    }

    & + & {
      margin-top: $space;

      @supports (display: grid) {
        margin-top: 0;
      }
    }

    &_double {
      flex: 1 0 100%;
      
      @include mq-600 {
        display: flex;
        grid-column: span 2;
      }

      div {

        @include mq-600 {
          max-width: 30ch;
          width: 50%;
        }

        + div {
          margin-top: $space;

          @include mq-600 {
            margin-left: $space;
            margin-top: 0;
          }
        }
      }
    }

    &_cta {
      flex: 1 0 100%;
      text-align: center;

      @include mq-600 {
        grid-column: span 2;
      }
    }
  }

  &__input {
    border: $border-thinner solid $light-medium-gray;
    border-radius: 0.25em;
    background: $lightest-gray;
    max-width: 30ch;
    padding: 0.25rem;
    width: 100%;

    &_checkbox {
      transform: scale(1.5);
    }
  }

  &__checkbox {
    display: flex;
    flex: 1 0 100%;
    margin: $space 0;

    @include mq-600 {
      align-content: center;
      justify-content: center;
      grid-column: span 2;
    }
  }

  &__label_checkbox {
    margin-left: calc($space * 0.5);
    margin-top: 0;
  }

  &__success {
    color: darkgreen;
    font-size: 0.75em;
    margin: 0.5em 0 0;
  }

  &__error {
    color: darkred;
    font-size: 0.75em;
    margin: 0.5em 0 0;
  }

  &__button {
    background: hsl(238.2, 51.8%, 37.5%);
    color: white;
    cursor: pointer;
    font-weight: 700;
    min-width: 8.25rem;
    padding: 0.75em 1em 0.5em;

    &:hover,
    &:focus {
      background: hsl(238.2, 51.8%, 55%);
      padding: 0.75em 1em 0.5em;
    }

    &[disabled] {
      background: hsl(238.2, 51.8%, 37.5%);
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
