.toggle {
  margin: 0.25rem 0;
  display: inline-flex;
  align-items: center;

  &__switch {
    margin: 0 0.5rem 0 0;
    cursor: pointer;
    position: relative;

    &::before,
    &::after {
      background: $light-gray;
      content: '';
      display: block;
      transition: all 100ms cubic-bezier( 0.4, 0.0, 0.2, 1 );
    }

    &::before {
      border-radius: 0.65em;
      height: 1.3em;
      opacity: 0.6;
      width: 3em;
    }

    &::after {
      border-radius: 50%;
      box-shadow: 0 0 8px rgba( 0, 0, 0, 0.2 ), 0 0 2px rgba( 0, 0, 0, 0.4 );
      height: 1.7em;
      position: absolute;
      top: 50%;
      transform: translate( 0, -50% );
      width: 1.7em;
    }
  }

  [type=checkbox] {
    opacity: 0;
    pointer-events: none;
    position: absolute;
  }
}

[type=checkbox] {

  /* Focused */

  &:focus + .toggle__switch {
    outline: $medium-blue solid 1px;
    box-shadow: 0 0 0.5em $medium-blue;
  }

  /* Disabled */

  &:disabled + .toggle__switch {
    opacity: 0.6;
    cursor: not-allowed;
    filter: grayscale( 100% );

    &::after {
      box-shadow: none;
    }
  }

  /* Checked */

  &:checked + .toggle__switch {

    &::before {
      background: $medium-blue;
    }

    &::after {
      background: $medium-blue;
      transform: translate( calc( 3em - 100% ), -50% );
    }
  }

}