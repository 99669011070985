@font-face {
  font-family: 'Benton Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/sir/benton-sans/benton-sans-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/sir/benton-sans/benton-sans-regular.woff') format('woff'); /* Pretty Modern Browsers */
}

@font-face {
  font-family: 'Benton Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/sir/benton-sans/benton-sans-bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/sir/benton-sans/benton-sans-bold.woff') format('woff'); /* Pretty Modern Browsers */
}