.certificate {
  display: grid;
  font-size: 0.65rem;
  grid-template-rows: auto 1fr auto;
  max-height: 11in;
  max-width: 8.5in;

  @media screen and (min-width: 8.5in) {
    border: 2px solid #D0CECE;
    margin: 1em auto;
  }

  @page { 
    margin: 0.5in;
    size: letter;
  }

  @media print {
    background: none;
    border: none;
    grid-template-rows: auto auto 1fr;
    height: 100%;
    margin: 0;
    max-height: 100%;
    max-width: 100%;
    padding: 0;
    width: 100%;
  }

  &_bhgre {
    border-bottom: 1.5em solid #339933;
    border-top: 1.5em solid #339933;

    .certificate__title {
      color: #339933;
      font-family: $font-stack-bhgre-title;
    }
  }

  &_c21 {
    border-bottom: 1.5em solid #beaf87;
    border-top: 1.5em solid #beaf87;

    .certificate__title {
      color: #beaf87;
      font-family: $font-stack-c21-title;
    }
  }

  &_cb {
    border-bottom: 1.5em solid #14377d;
    border-top: 1.5em solid #14377d;

    .certificate__title {
      color: #14377d;
    }
  }

  &_corcoran {
    border-bottom: 1.5em solid #000000;
    border-top: 1.5em solid #000000;

    .certificate__title {
      color: #000000;
    }
  }

  &_era {
    border-bottom: 1.5em solid #0b3279;
    border-top: 1.5em solid #0b3279;

    .certificate__title {
      color: #0b3279;
    }
  }

  &_realogy {
    border-bottom: 1.5em solid #104E42;
    border-top: 1.5em solid #104E42;

    .certificate__title {
      color: #104E42;
    }
  }

  &_sir {
    border-bottom: 1.5em solid #0C2349;
    border-top: 1.5em solid #0C2349;

    .certificate__title {
      color: #0C2349;
    }
  }

  &__header {
    text-align: center;
  }

  &__title {
    font-size: 2rem;
    font-weight: 300;
    margin-bottom: 1em;
    text-transform: uppercase;
  }

  &__logo {
    margin: 0 auto;
    max-height: 3rem;
    max-width: 10rem;
  }

  &__main {
    align-content: start;
    display: grid;
    grid-template-rows: repeat(6, auto) 1fr;
    text-align: center;

    @media print {
      grid-template-rows: repeat(7, auto);
    }

    > :last-child {
      align-self: end;
    }
  }

  &__signature {
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
    width: 200px;
  }

  &__icon {
    margin-left: auto;
    margin-right: auto;
    width: 3rem;
  }

  &__nowrap {
    white-space: nowrap;
  }

  &__footer {
  }

  &__disclaimer {
    font-size: 0.5em;
  }
}