a {
  background-color: transparent;
	transition: color $speed-fast ease;

  &:link,
  &:visited {
  	color: $medium-blue;

    /*@media (prefers-color-scheme: dark) {
      color: $light-blue;
    }*/
  }

  &:hover,
  &:focus {
  	color: $light-medium-blue;

    /*@media (prefers-color-scheme: dark) {
      color: $lighter-blue;
    }*/
  }

  &:active {
  	color: $light-blue;

    /*@media (prefers-color-scheme: dark) {
      color: $lightest-blue;
    }*/
  }
}