.pad {
  padding: $space;

  &-top {
    padding-top: $space;
  }

  &-bottom {
    padding-bottom: $space;
  }

  &-y {
    padding-top: $space;
    padding-bottom: $space;
  }

  &-left {
    padding-left: $space;
  }

  &-right {
    padding-right: $space;
  }

  &-x {
    padding-left: $space;
    padding-right: $space;
  }

  &-2 {
    padding: $space-2;
  }

  &-top-2 {
    padding-top: $space-2;
  }

  &-bottom-2 {
    padding-bottom: $space-2;
  }

  &-y-2 {
    padding-top: $space-2;
    padding-bottom: $space-2;
  }

  &-left-2 {
    padding-left: $space-2;
  }

  &-right-2 {
    padding-right: $space-2;
  }

  &-x-2 {
    padding-left: $space-2;
    padding-right: $space-2;
  }
}