nav {
	ul,
	ol {
		font-size: 0;
		margin: 0;

		@include mq-600 {
			display: flex;
			justify-content: flex-start;
		}
	}

	li {
		display: inline-block;
		font-size: 1rem;
	}

	a {
		padding: 0.5rem;
		text-decoration: none;

		&:link,
		&:visited {
			text-decoration: none;
		}

		&:hover,
		&:focus {
			text-decoration: underline;
		}

		&:focus {
			outline-offset: -0.125em;
		}
	}

	button {
		@include mq-600 {
			display: none;
		}
	}
}