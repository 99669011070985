.button {
	background: hsl( 240, 100%, 50% );
	border-radius: 0.25em;
	box-shadow: 0 0.125rem 0 0 hsl( 240, 25%, 90% );
	color: hsl( 0, 0%, 100% );
	display: inline-block;
  line-height: 1.4;
	padding: 0.25em 0.5em;
	text-decoration: none;
	text-transform: capitalize;
	transition: box-shadow 200ms ease;

	&:link,
	&:visited {
		box-shadow: 0 0.125rem 0 0 hsl( 240, 25%, 90% );
		color: hsl( 0, 0%, 100% );
	}

	&:hover,
	&:focus {
		background: hsl( 220, 100%, 50% );
		box-shadow: 0 0.125rem 0 0 hsl( 240, 25%, 80% );
		color: hsl( 0, 0%, 100% );
	}

	&:active {
		background: hsl( 220, 100%, 60% );
		box-shadow: none;
		color: hsl( 0, 0%, 100% );
	}

	&[disabled] {
		background: hsl( 240, 50%, 80% );
		box-shadow: none;
		color: hsl( 0, 0%, 100% );
		cursor: auto;
	}

	&__ghost {
		background: transparent;
		border: 0.125rem solid;
		color: hsl( 240, 100%, 50% );
		padding: 0.125em 0.375em;

		&:link,
		&:visited {
			background: transparent;
			border: 0.125rem solid;
			color: hsl( 240, 100%, 50% );
		}

		&:hover,
		&:focus {
			background: transparent;
			color: hsl( 220, 100%, 50% );
		}

		&:active {
			background: transparent;
			color: hsl( 220, 100%, 60% );
		}

		&[disabled] {
			background: transparent;
			color: hsl( 240, 50%, 80% );
			cursor: auto;
		}
	}
}