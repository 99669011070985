/**
  * Settings: Global variables, config switches
  * Tools: Default mixins and functions
  * Generic: Ground-zero styles (Normalize.css, resets, box-sizing)
  * Elements: Unclassed HTML elements (type selectors)
  * Objects: Cosmetic-free design patterns
  * Components: Designed components, chunks of UI
  * Utilities: Helpers and overrides
  */
/**
  * Import web fonts
  */
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/corcoran/avenir/Avenir-Roman.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/corcoran/avenir/Avenir-Heavy.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('../fonts/BarlowCondensed-Bold.ttf') format('truetype');
  /* Legacy iOS */
}

@font-face {
  font-family: 'Benton Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/sir/benton-sans/benton-sans-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/sir/benton-sans/benton-sans-regular.woff') format('woff');
  /* Pretty Modern Browsers */
}

@font-face {
  font-family: 'Benton Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/sir/benton-sans/benton-sans-bold.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/sir/benton-sans/benton-sans-bold.woff') format('woff');
  /* Pretty Modern Browsers */
}

@font-face {
  font-family: 'Corporate S';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/bhgre/corporate-s/corporates-light-webfont.woff') format('woff'),
    /* Pretty Modern Browsers */
    url('../fonts/bhgre/corporate-s/corporates-light-webfont.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Corporate S';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/bhgre/corporate-s/corporates-regular-webfont.woff') format('woff'),
    /* Pretty Modern Browsers */
    url('../fonts/bhgre/corporate-s/corporates-regular-webfont.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Corporate S';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/bhgre/corporate-s/corporates-demi-webfont.woff') format('woff'),
    /* Pretty Modern Browsers */
    url('../fonts/bhgre/corporate-s/corporates-demi-webfont.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Corporate S';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/bhgre/corporate-s/corporates-bold-webfont.woff') format('woff'),
    /* Pretty Modern Browsers */
    url('../fonts/bhgre/corporate-s/corporates-bold-webfont.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/era/gotham/gotham-book-webfont.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/era/gotham/gotham-book-webfont.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/era/gotham/gotham-book-webfont.woff') format('woff'),
    /* Pretty Modern Browsers */
    url('../fonts/era/gotham/gotham-book-webfont.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/era/gotham/gotham-book-webfont.svg#svgFontName') format('svg');
  /* Legacy iOS */
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/era/gotham/gotham-medium-webfont.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/era/gotham/gotham-medium-webfont.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/era/gotham/gotham-medium-webfont.woff') format('woff'),
    /* Pretty Modern Browsers */
    url('../fonts/era/gotham/gotham-medium-webfont.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/era/gotham/gotham-medium-webfont.svg#svgFontName') format('svg');
  /* Legacy iOS */
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wXiWtFCc.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Lato Bold'), local('Lato-Bold'), url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPGQ3q5d0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Oakes';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/c21/oakes/oakes-regular/webfonts/oakes-regular.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/c21/oakes/oakes-regular/webfonts/oakes-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/c21/oakes/oakes-regular/webfonts/oakes-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/c21/oakes/oakes-regular/webfonts/oakes-regular.woff') format('woff'),
    /* Pretty Modern Browsers */
    url('../fonts/c21/oakes/oakes-regular/webfonts/oakes-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/c21/oakes/oakes-regular/webfonts/oakes-regular.svg#svgFontName') format('svg');
  /* Legacy iOS */
}

@font-face {
  font-family: 'Oakes';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/c21/oakes/oakes-regular/webfonts/oakes-regular-italic.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/c21/oakes/oakes-regular-italic/webfonts/oakes-regular-italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/c21/oakes/oakes-regular-italic/webfonts/oakes-regular-italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/c21/oakes/oakes-regular-italic/webfonts/oakes-regular-italic.woff') format('woff'),
    /* Pretty Modern Browsers */
    url('../fonts/c21/oakes/oakes-regular-italic/webfonts/oakes-regular-italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/c21/oakes/oakes-regular-italic/webfonts/oakes-regular-italic.svg#svgFontName') format('svg');
  /* Legacy iOS */
}

@font-face {
  font-family: 'Oakes';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/c21/oakes/oakes-bold/webfonts/oakes-bold.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/c21/oakes/oakes-bold/webfonts/oakes-bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/c21/oakes/oakes-bold/webfonts/oakes-bold.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/c21/oakes/oakes-bold/webfonts/oakes-bold.woff') format('woff'),
    /* Pretty Modern Browsers */
    url('../fonts/c21/oakes/oakes-bold/webfonts/oakes-bold.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/c21/oakes/oakes-bold/webfonts/oakes-bold.svg#svgFontName') format('svg');
  /* Legacy iOS */
}

@font-face {
  font-family: 'Oakes';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/c21/oakes/oakes-bold/webfonts/oakes-bold-italic.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/c21/oakes/oakes-bold-italic/webfonts/oakes-bold-italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/c21/oakes/oakes-bold-italic/webfonts/oakes-bold-italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/c21/oakes/oakes-bold-italic/webfonts/oakes-bold-italic.woff') format('woff'),
    /* Pretty Modern Browsers */
    url('../fonts/c21/oakes/oakes-bold-italic/webfonts/oakes-bold-italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/c21/oakes/oakes-bold-italic/webfonts/oakes-bold-italic.svg#svgFontName') format('svg');
  /* Legacy iOS */
}

@font-face {
  font-family: 'Rockwell';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/bhgre/rockwell/Rockwell-Light.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/bhgre/rockwell/Rockwell-Light.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/bhgre/rockwell/Rockwell-Light.woff') format('woff'),
    /* Pretty Modern Browsers */
    url('../fonts/bhgre/rockwell/Rockwell-Light.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Rockwell';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/bhgre/rockwell/Rockwell-Regular.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/bhgre/rockwell/Rockwell-Regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/bhgre/rockwell/Rockwell-Regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/bhgre/rockwell/Rockwell-Regular.woff') format('woff'),
    /* Pretty Modern Browsers */
    url('../fonts/bhgre/rockwell/Rockwell-Regular.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Rockwell';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/bhgre/rockwell/Rockwell-Italic.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/bhgre/rockwell/Rockwell-Italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/bhgre/rockwell/Rockwell-Italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/bhgre/rockwell/Rockwell-Italic.woff') format('woff'),
    /* Pretty Modern Browsers */
    url('../fonts/bhgre/rockwell/Rockwell-Italic.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Rockwell';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/bhgre/rockwell/Rockwell-Bold.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/bhgre/rockwell/Rockwell-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/bhgre/rockwell/Rockwell-Bold.woff') format('woff'),
    /* Pretty Modern Browsers */
    url('../fonts/bhgre/rockwell/Rockwell-Bold.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Sign Painter';
  src: url('../fonts/signpainter.ttf') format('truetype');
  /* Legacy iOS */
}

@font-face {
  font-family: 'Typold Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/c21/typold/Typold-Condensed-Regular.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Typold Condensed';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/c21/typold/Typold-Condensed-Medium.ttf') format('truetype');
  /* Safari, Android, iOS */
}

/**
  * Declare font stacks
  */
/* Brand font stacks */
/*@import './tools/*.css';*/
/**
  * A bare-bones CSS reset for modern web development, with some modifications.
  * https://github.com/hankchizljaw/modern-css-reset
  */
* {
  color: inherit;
  font-family: inherit;
  line-height: inherit
    /* Box sizing rules */
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article>*+* {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

input,
textarea {
  letter-spacing: inherit;
  word-spacing: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

@-o-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

a {
  background-color: transparent;
  transition: color 200ms ease
}

a:link,
a:visited {
  color: hsl(240, 100%, 50%);

  /*@media (prefers-color-scheme: dark) {
      color: $light-blue;
    }*/
}

a:hover,
a:focus {
  color: hsl(220, 100%, 50%);

  /*@media (prefers-color-scheme: dark) {
      color: $lighter-blue;
    }*/
}

a:active {
  color: hsl(220, 100%, 70%);

  /*@media (prefers-color-scheme: dark) {
      color: $lightest-blue;
    }*/
}

abbr {
  font-feature-settings: "kern", "liga", "clig", "calt", "c2sc", "smcp";
  letter-spacing: 0.01em;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted
}

@supports (font-variant-caps: all-small-caps) {
  abbr {
    font-variant-caps: all-small-caps;
    font-feature-settings: normal, "smcp", "c2sc", "smcp", "c2sc"
  }
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

acronym {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

audio {
  height: auto;
  max-width: 100%;
}

b {
  font-weight: bolder;
}

big {
  font-size: 112.5%
}

@media screen and (min-width: 75rem) {
  big {
    /* Desktop / 75rem / 1200px */
    font-size: 1.25rem
  }
}

blockquote {
  margin: calc(1rem * 2);
  text-align: center;
}

blockquote p {
  line-height: 1.3;
  margin-bottom: 0.5em;
}

blockquote cite {
  font-weight: 700;
}

body {
  background: hsl(0, 0%, 100%);
  color: hsl(0, 0%, 0%);
  font: 400 16px/1.5 sans-serif;
  font: 400 1rem/1.5 sans-serif;
  margin: 0;
  overflow-y: auto;
  /* Hide persistent scrollbar in IE (10/11) and Edge */
  -ms-overflow-style: -ms-autohiding-scrollbar;

  /*@media (prefers-color-scheme: dark) {
    background: $darkest-gray;
    color: $white;
  }*/
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-family: inherit;
  font-size: 100%;
  line-height: 1;
  margin: 0;
  overflow: visible;
  padding: 4px;
  padding: 0.25rem;
  text-transform: capitalize;
  -webkit-appearance: button;
  transition: all 200ms ease
}

button[disabled] {
  color: hsl(0, 0%, 70%);
  cursor: auto;
}

button {

  /* Isolate this selector */

}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button {

  /* Isolate this selector */

}

button:-moz-focusring {
  outline: 1.04px dotted ButtonText;
  outline: 0.065rem dotted ButtonText;
}

[type="button"] {
  border: none;
  border-radius: 0.25em;
  padding: 4px;
  padding: 0.25rem;
  text-transform: capitalize;
  transition: box-shadow 200ms ease;
  -webkit-appearance: button;
}

[type="reset"],
[type="submit"] {
  border-radius: 0.25em;
  -webkit-appearance: button;
}

/* Isolate this selector */
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  border-style: none;
  outline: 1.04px dotted ButtonText;
  outline: 0.065rem dotted ButtonText;
  padding: 0;
}

/* Isolate this selector */
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

caption {
  line-height: 1.3;
  padding: 16px;
  padding: 1rem;
  text-transform: capitalize;
}

code {
  background: hsl(0, 0%, 100%);
  border-radius: 0.25em;
  font-family: monospace;
  font-size: 13.6px;
  font-size: 0.85rem;
  margin: 0 2px;
  margin: 0 0.125rem;
  padding: 0 4px;
  padding: 0 0.25rem;
}

details {
  border-color: hsl(0, 0%, 70%);
  border-radius: 0.25em;
  display: block;
  margin: 2em 0
}

details[open] {
  background: hsl(0, 0%, 100%);
  padding: 16px;
  padding: 1rem;
}

details:not([open]) summary {
  padding: 16px;
  padding: 1rem;
}

em {
  font-style: italic;
}

embed {
  height: auto;
  max-width: 100%;
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

+fieldset {
  margin-top: calc(1rem * 2);
}

:focus {
  outline: 2px dashed hsl(220, 100%, 80%);
  outline: 0.125rem dashed hsl(220, 100%, 80%);
}

[aria-invalid] {
  border-color: red;
}

h1 {
  font-size: 32px;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.1;
  margin: 0 0 0.5em;
}

h2 {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.1;
  margin: 0 0 0.5em;
}

h3 {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.125;
  margin: 0 0 0.6em;
}

h4 {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.125;
  margin: 0 0 0.6em;
}

h5 {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.4;
  margin: 0 0 0.7em;
}

h6 {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  margin: 0 0 0.7em;
}

@media screen and (min-width: 75rem) {

  /* Desktop / 75rem / 1200px */
  h1 {
    font-size: 3rem;
  }

  /*h2 {
    font-size: 2rem;
  }*/

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.25rem;
  }
}

[hidden] {
  display: none;
}

hr {
  background: hsl(0, 0%, 70%);
  border: none;
  height: 2px;
  height: 0.125rem;
  margin: 16px 0;
  margin: 1rem 0;
}

i {
  font-style: italic;
}

iframe {
  border: none;
  height: auto;
  max-width: 100%;
}

img {
  max-width: 100%;

  /*@media (prefers-color-scheme: dark) {
    filter: grayscale(20%);
  }*/
}

input {
  border: none;
  cursor: pointer;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.4;
  margin: 0;
  overflow: visible
}

input:placeholder {
  color: hsl(0, 0%, 50%);
}

input:disabled,
input:readonly {
  color: hsl(0, 0%, 50%);
  cursor: not-allowed;
}

/* Normalise form field line-height in WebKit */
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
select::-webkit-input-placeholder {
  line-height: normal;
}

[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="week"],
[type="number"],
[type="password"],
[type="search"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"] {
  border: 1.04px solid hsl(0, 0%, 60%);
  border: 0.065rem solid hsl(0, 0%, 60%);
  border-radius: 0.25em;
  background: hsl(0, 0%, 90%);
  padding: 4px;
  padding: 0.25rem;
}

[type="checkbox"] {
  padding: 0;
}

/* TODO: Style color picker input: https://jsfiddle.net/9zhap7rb/3/ */
/* TODO: Style file upload input: http://jsfiddle.net/barney/uxPjN/ */
/* Isolate this selector */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="radio"] {
  padding: 0;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* Turn off the recent search in WebKit. */
::-webkit-search-decoration {
  display: none;
  -webkit-appearance: none;
}

kbd {
  background: hsl(0, 0%, 100%);
  border-radius: 0.25em;
  box-shadow: 0 0.0625rem 0.0625rem 0.0625rem hsl(0, 0%, 80%);
  display: inline-block;
  font-family: monospace;
  font-size: 13.6px;
  font-size: 0.85rem;
  margin: 0 2px;
  margin: 0 0.125rem;
  padding: 0 4px;
  padding: 0 0.25rem;
  transition: box-shadow 200ms ease
}

kbd:hover,
kbd:focus {
  box-shadow: 0 0.0625rem 0.0625rem 0.0625rem hsl(0, 0%, 50%);
}

label {
  display: block;
  font-size: 0.75em;
  text-transform: capitalize;
}

label+* {
  margin-top: 4px;
  margin-top: 0.25rem;
}

[type="radio"]+label {
  padding-left: 0.25em;
}

legend {
  display: table;
  font-weight: 700;
  margin-bottom: 0.75em;
  max-width: 100%;
  padding: 0;
  text-transform: capitalize;
  white-space: normal;
}

ul,
ol {
  margin: 0 0 0.7em;
}

dl {
  color: hsl(0, 0%, 30%);
  line-height: 1.4;
  margin: 0 0 0.7em;
  padding: 0 0 0 1em;
}

dd {
  margin: 0;
  padding: 0 0 0 2em;
}

dd+dt {
  margin: 1em 0 0;
}

dt {
  font-weight: 700;
}

nav ul,
nav ol {
  font-size: 0;
  margin: 0
}

@media screen and (min-width: 37.5rem) {

  nav ul,
  nav ol {
    /* Tablet Portrait / 37.5rem / 600px */
    display: flex;
    justify-content: flex-start
  }
}

nav li {
  display: inline-block;
  font-size: 16px;
  font-size: 1rem;
}

nav a {
  padding: 8px;
  padding: 0.5rem;
  text-decoration: none
}

nav a:link,
nav a:visited {
  text-decoration: none;
}

nav a:hover,
nav a:focus {
  text-decoration: underline;
}

nav a:focus {
  outline-offset: -0.125em;
}

@media screen and (min-width: 37.5rem) {

  nav button {
    /* Tablet Portrait / 37.5rem / 600px */
    display: none
  }
}

object {
  height: auto;
  max-width: 100%;
}

optgroup {
  font-size: 100%;
  margin: 0;
}

p {
  /* TODO (paulshryock): Test autoprefixer results before removing prefixes */
  -webkit-hyphens: auto;
  -webkit-hyphenate-limit-before: 3;
  -webkit-hyphenate-limit-after: 3;
  -webkit-hyphenate-limit-chars: 8 3 3;
  -webkit-hyphenate-limit-lines: 2;
  -webkit-hyphenate-limit-last: always;
  -webkit-hyphenate-limit-zone: 8%;

  -moz-hyphens: auto;
  -moz-hyphenate-limit-chars: 8 3 3;
  -moz-hyphenate-limit-lines: 2;
  -moz-hyphenate-limit-last: always;
  -moz-hyphenate-limit-zone: 8%;

  -ms-hyphens: auto;
  -ms-hyphenate-limit-chars: 8 3 3;
  -ms-hyphenate-limit-lines: 2;
  -ms-hyphenate-limit-last: always;
  -ms-hyphenate-limit-zone: 8%;

  hyphens: auto;
  hyphenate-limit-chars: 8 3 3;
  hyphenate-limit-lines: 2;
  hyphenate-limit-last: always;
  hyphenate-limit-zone: 8%;

  /*word-break: break-all;*/

  margin: 0 0 0.7em;
}

pre {
  font-family: monospace;
  font-size: 16px;
  font-size: 1rem;
  -moz-tab-size: 2;
  -o-tab-size: 2;
  tab-size: 2;

  /* Browser specific (not valid) styles to make preformatted text wrap */
  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-break: break-word;
  /* Internet Explorer 5.5+ */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
  word-wrap: break-spaces;
  /* Internet Explorer 5.5+ */
  word-wrap: break-word;
}

/* Internet Explorer 5.5+ */
pre code {
  display: block;
  line-height: 1.2;
  margin: 0;
  padding: 16px;
  padding: 1rem;
}

progress {
  vertical-align: baseline;
}

q {
  font-style: italic;
  quotes: "“" "”" "‘" "’"
}

q:before {
  content: open-quote;
}

q:after {
  content: close-quote;
}

:root {
  font-feature-settings: "kern", "liga", "clig", "calt", "onum", "pnum";
  font-size: calc(1 * 100%);
  /* 1 * 16px = 16px base rem unit */
  font-size-adjust: 0.516
    /* Set x-height */
}

@supports (font-kerning: normal) and (font-variant-ligatures: common-ligatures contextual) and (font-variant-numeric: oldstyle-nums proportional-nums) {
  :root {
    font-kerning: normal;
    font-variant-ligatures: common-ligatures contextual;
    font-variant-numeric: oldstyle-nums proportional-nums;
    font-feature-settings: normal, "kern", common-ligatures contextual, oldstyle-nums proportional-nums, "kern", common-ligatures contextual, oldstyle-nums proportional-nums
  }
}

@media screen and (min-width: 37.5rem) {
  :root {
    /* Tablet Portrait / 37.5rem / 600px */
    font-size: calc(1.125 * 100%)
      /* 1.125 * 16px = 18px base rem unit */
  }
}

@media screen and (min-width: 56.25rem) {
  :root {
    /* Tablet Landscape / 56.25rem / 900px */
    font-size: calc(1.25 * 100%)
      /* 1.25 * 16px = 20px base rem unit */
  }
}

@media screen and (min-width: 75rem) {
  :root {
    /* Desktop / 75rem / 1200px */
    font-size: calc(1.375 * 100%)
      /* 1.375 * 16px = 22px base rem unit */
  }
}

@media screen and (min-width: 112.5rem) {
  :root {
    /* Big Desktop / 112.5rem / 1800px */
    font-size: calc(1.5 * 100%)
      /* 1.5 * 16px = 24px base rem unit */
  }
}

samp {
  font-family: monospace;
  font-size: 13.6px;
  font-size: 0.85rem;
  font-weight: 700;
}

select {
  border-radius: 0.25em;
  display: block;
  font-size: 100%;
  margin: 0;
  text-transform: none;
}

small {
  font-size: 85%;
}

strong {
  font-weight: bolder;
}

sub {
  bottom: -0.25em;
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

summary {
  cursor: pointer;
  display: list-item;
  /*list-style-image: url(right-arrow.svg);*/

  /*&::-webkit-details-marker {
    background: url(right-arrow.svg);
    color: transparent;
  }*/
}

sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  top: -8px;
  top: -0.5rem;
  vertical-align: baseline;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  color: hsl(0, 0%, 30%);
  margin: 2em 0;
  table-layout: fixed;
  width: 100%;
}

td {
  line-height: 1.3;
  padding: 0.5em;
}

template {
  display: none;
}

textarea {
  border: none;
  border-radius: 0.25em;
  box-shadow: inset 0 0.0625rem 0.0625rem 0.0625rem hsl(0, 0%, 50%);
  display: block;
  font-size: 100%;
  margin: 0;
  max-width: 100%;
  overflow: auto;
  padding: 4px;
  padding: 0.25rem;
}

tfoot {
  background: hsl(0, 0%, 80%);
}

tfoot th,
tfoot td {
  border-bottom: none;
}

th {
  font-weight: 700;
  line-height: 1.3;
  padding: 0.5em;
  text-align: left;
}

thead {
  background: hsl(0, 0%, 10%);
  color: hsl(0, 0%, 100%);
}

tr:nth-of-type(even) {
  background: hsl(0, 0%, 90%);
}

tt {
  font-family: monospace;
  font-size: 13.6px;
  font-size: 0.85rem;
}

var {
  font-family: monospace;
  font-size: 13.6px;
  font-size: 0.85rem;
}

video {
  height: auto;
  max-width: 100%;
}

/*@import './objects/*.css';*/
.admin {
  background: #eee;
}

.admin__masthead {
  background: #ccc;
  border-bottom: 0.25em solid hsl(240, 100%, 50%);
  padding: 16px;
  padding: 1rem;
}

@media screen and (min-width: 37.5rem) {

  .admin__masthead-layout {
    /* Tablet Portrait / 37.5rem / 600px */
    display: grid;
    grid-template-columns: 1fr auto
  }
}

.admin__masthead-title {
  font-size: 1em;
  margin-bottom: 0
}

@media screen and (min-width: 37.5rem) {

  .admin__masthead-title {
    /* Tablet Portrait / 37.5rem / 600px */
    padding-top: 0.25em
  }
}

.admin__nav {
  padding-top: 0.5em
}

@media screen and (min-width: 37.5rem) {

  .admin__nav {
    /* Tablet Portrait / 37.5rem / 600px */
    line-height: 1.5;
    padding-top: 0
  }
}

.admin__nav-list {
  margin-left: -0.5em;
  margin-right: -0.5em;
}

.admin__nav-list-item {
  display: block
}

@media screen and (min-width: 37.5rem) {

  .admin__nav-list-item {
    /* Tablet Portrait / 37.5rem / 600px */
    display: inline-block
  }
}

.admin__nav-list-item:first-child .admin__nav-link {
  padding-left: 0;
}

.admin__nav-list-item:last-child .admin__nav-link {
  padding-right: 0;
}

.admin__nav-list-item_button {
  margin-bottom: 8px;
  margin-bottom: 0.5rem;
  margin-top: 8px;
  margin-top: 0.5rem
}

@media screen and (min-width: 37.5rem) {

  .admin__nav-list-item_button {
    /* Tablet Portrait / 37.5rem / 600px */
    margin-bottom: 0;
    margin-top: 0
  }
}

.admin__nav-link {
  padding-left: 0;
  padding-right: 0
}

@media screen and (min-width: 37.5rem) {

  .admin__nav-link {
    /* Tablet Portrait / 37.5rem / 600px */
    padding-left: 0.5em;
    padding-right: 0.5em
  }
}

.admin__form {
  border: none;
}

.admin__form-title {
  font-size: 1.5em;
}

.admin__success {
  color: green;
}

.admin__error {
  color: red;
}

.admin__table {
  font-size: 0.75em;
  margin: 0;
  table-layout: auto;

}

.admin__table th,
.admin__table td {
  padding-bottom: 4px;
  padding-bottom: 0.25rem;
  padding-top: 4px;
  padding-top: 0.25rem
}

.admin__table th:first-child,
.admin__table td:first-child {
  padding-left: 16px;
  padding-left: 1rem;
}

.admin__table+.admin__table {
  border-top: 0.25em solid hsl(240, 100%, 50%);
}

.admin__message> :last-child {
  margin-bottom: 0;
}

.admin__download-button {
  background: hsl(240, 100%, 50%);
  border-radius: 0.25em;
  box-shadow: 0 0.125rem 0 0 hsl(240, 25%, 90%);
  color: hsl(0, 0%, 100%);
  display: inline-block;
  text-decoration: none;
  text-transform: capitalize;
  transition: box-shadow 200ms ease
}

@media screen and (min-width: 37.5rem) {

  .admin__download-button {
    /* Tablet Portrait / 37.5rem / 600px */
    margin-right: 1rem
  }
}

.admin__download-button:link,
.admin__download-button:visited {
  box-shadow: 0 0.125rem 0 0 hsl(240, 25%, 90%);
  color: hsl(0, 0%, 100%);
}

.admin__download-button:hover,
.admin__download-button:focus {
  background: hsl(220, 100%, 50%);
  box-shadow: 0 0.125rem 0 0 hsl(240, 25%, 80%);
  color: hsl(0, 0%, 100%);
  padding: 4px;
  padding: 0.25rem;
}

.admin__download-button:active {
  background: hsl(220, 100%, 60%);
  box-shadow: none;
  color: hsl(0, 0%, 100%);
  padding: 4px;
  padding: 0.25rem;
}

[data-state="inactive"] {
  display: none;
}

[data-state="success"] p {
  color: green;
}

[data-state="success"] p>button {
  margin: 0 16px;
  margin: 0 1rem;
}

[data-state="error"] p {
  color: red;
}

[data-state="error"] p>button {
  margin: 0 16px;
  margin: 0 1rem;
}

/**
  * Aspect ratio utility
  *
  * [Creating an aspect ratio CSS utility](https://andy-bell.design/wrote/creating-an-aspect-ratio-css-utility/)
  *
  * Resources:
  * - [Designing An Aspect Ratio Unit For CSS](https://www.smashingmagazine.com/2019/03/aspect-ratio-unit-css/)
  * - [CSS Box Sizing Module Level 4](https://drafts.csswg.org/css-sizing-4/)
  */
[class*='ratio-'] {
  display: block;
  position: relative;
}

[class*='ratio-']>* {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.aspect-ratio-wide {
  padding-top: 56.25%;
  /*aspect-ratio: 16/9;*/
}

.aspect-ratio-square {
  padding-top: 100%;
  /*aspect-ratio: 1/1;*/
}

.button {
  background: hsl(240, 100%, 50%);
  border-radius: 0.25em;
  box-shadow: 0 0.125rem 0 0 hsl(240, 25%, 90%);
  color: hsl(0, 0%, 100%);
  display: inline-block;
  line-height: 1.4;
  padding: 0.25em 0.5em;
  text-decoration: none;
  text-transform: capitalize;
  transition: box-shadow 200ms ease
}

.button:link,
.button:visited {
  box-shadow: 0 0.125rem 0 0 hsl(240, 25%, 90%);
  color: hsl(0, 0%, 100%);
}

.button:hover,
.button:focus {
  background: hsl(220, 100%, 50%);
  box-shadow: 0 0.125rem 0 0 hsl(240, 25%, 80%);
  color: hsl(0, 0%, 100%);
}

.button:active {
  background: hsl(220, 100%, 60%);
  box-shadow: none;
  color: hsl(0, 0%, 100%);
}

.button[disabled] {
  background: hsl(240, 50%, 80%);
  box-shadow: none;
  color: hsl(0, 0%, 100%);
  cursor: auto;
}

.button__ghost {
  background: transparent;
  border: 2px solid;
  border: 0.125rem solid;
  color: hsl(240, 100%, 50%);
  padding: 0.125em 0.375em
}

.button__ghost:link,
.button__ghost:visited {
  background: transparent;
  border: 2px solid;
  border: 0.125rem solid;
  color: hsl(240, 100%, 50%);
}

.button__ghost:hover,
.button__ghost:focus {
  background: transparent;
  color: hsl(220, 100%, 50%);
}

.button__ghost:active {
  background: transparent;
  color: hsl(220, 100%, 60%);
}

.button__ghost[disabled] {
  background: transparent;
  color: hsl(240, 50%, 80%);
  cursor: auto;
}

@media (max-width: 400px) {
  .headerImg {
    max-width: 400px;
    width: auto;
  }

  .footerImg {
    max-width: 400px;
    width: auto;
  }

  .caption {
    font-size: 10px;
    margin-left: 1mm;
    max-width: 60ch;
  }
}

@media (min-width: 401px) and (max-width: 1198px) {
  .headerImg {
    max-width: 700px;
    width: auto;
  }

  .footerImg {
    max-width: 700px;
    width: auto;
  }

  /* delete caption color */
  .caption {
    margin-left: 9mm;
    max-width: 70ch;
    font-size: 15px;
  }
}

@media (min-width: 1198px) {
  img {
    max-width: 1199px;
    width: auto;
  }

  .headerImg {
    margin-left: 4px;
  }

  .footerImg {
    margin-top: 4em;
  }

  .caption {
    font-size: 20px;
    margin-left: 23mm;
    max-width: 85ch;
  }
}

.printWarningBanner {
  animation-name: printableWarning;
  animation-duration: 10s;
  animation-timing-function: ease-out;
  animation-fill-mode: forward;
  width: 70%;
  padding: 20px;
  visibility: hidden;
  background-color: transparent;
  color: transparent;
  position: absolute;
}

@keyframes printableWarning {
  0% {
    visibility: visible;
    background-color: blue;
    color: white;
  }

  100% {
    visibility: visible;
    background-color: transparent;
    color: transparent;
  }
}

.certificate {
  display: grid;
  font-size: 10.4px;
  font-size: 0.65rem;
  grid-template-rows: auto 1fr auto;
  max-height: 11in;
  max-width: 8.5in
}

@media screen and (min-width: 8.5in) {
  .certificate {
    border: 2px solid #D0CECE;
    margin: 1em auto
  }
}

.certificate {

  @page {
    margin: 0.5in;
    size: letter;
  }
}

@media print {
  .certificate {
    background: none;
    border: none;
    grid-template-rows: auto auto 1fr;
    height: 100%;
    margin: 0;
    max-height: 100%;
    max-width: 100%;
    padding: 0;
    width: 100%
  }
}

.certificate_bhgre {
  border-bottom: 1.5em solid #339933;
  border-top: 1.5em solid #339933;
}

.certificate_bhgre .certificate__title {
  color: #339933;
  font-family: 'Rockwell', 'Corporate S', Arial, sans-serif;
}

.certificate_c21 {
  border-bottom: 1.5em solid #beaf87;
  border-top: 1.5em solid #beaf87;
}

.certificate_c21 .certificate__title {
  color: #beaf87;
  font-family: 'Typold Condensed', 'Oakes', sans-serif;
}

.certificate_cb {
  border-bottom: 1.5em solid #14377d;
  border-top: 1.5em solid #14377d;
}

.certificate_cb .certificate__title {
  color: #14377d;
}

.certificate_corcoran {
  border-bottom: 1.5em solid #000000;
  border-top: 1.5em solid #000000;
}

.certificate_corcoran .certificate__title {
  color: #000000;
}

.certificate_era {
  border-bottom: 1.5em solid #0b3279;
  border-top: 1.5em solid #0b3279;
}

.certificate_era .certificate__title {
  color: #0b3279;
}

.certificate_realogy {
  border-bottom: 1.5em solid #104E42;
  border-top: 1.5em solid #104E42;
}

.certificate_realogy .certificate__title {
  color: #104E42;
}

.certificate_sir {
  border-bottom: 1.5em solid #0C2349;
  border-top: 1.5em solid #0C2349;
}

.certificate_sir .certificate__title {
  color: #0C2349;
}

.certificate__header {
  text-align: center;
}

.certificate__title {
  font-size: 32px;
  font-size: 2rem;
  font-weight: 300;
  margin-bottom: 1em;
  text-transform: uppercase;
}

.certificate__logo {
  margin: 0 auto;
  max-height: 48px;
  max-height: 3rem;
  max-width: 160px;
  max-width: 10rem;
}

.certificate__main {
  align-content: start;
  display: grid;
  grid-template-rows: repeat(6, auto) 1fr;
  text-align: center
}

@media print {

  .certificate__main {
    grid-template-rows: repeat(7, auto)
  }
}

.certificate__main> :last-child {
  align-self: end;
}

.certificate__signature {
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
  width: 200px;
}

.certificate__icon {
  margin-left: auto;
  margin-right: auto;
  width: 48px;
  width: 3rem;
}

.certificate__nowrap {
  white-space: nowrap;
}

.certificate__footer {}

.certificate__disclaimer {
  font-size: 0.5em;
}

.colophon {
  font-size: 0.75em;
  padding: 16px;
  padding: 1rem;
  text-align: center;
}

.container {
  margin: 0 auto;
  max-width: 65ch;
  width: 100%;
}

.em {
  font-style: italic;
}

.form {
  border: 0.5em solid hsl(0, 0%, 0%);
  margin: 0;
  padding: 16px;
  padding: 1rem;
}

@media screen and (min-width: 37.5rem) {

  .form__fieldset {
    /* Tablet Portrait / 37.5rem / 600px */
    display: flex;
    display: grid;
    flex-wrap: wrap;
    grid-gap: 1rem;
    grid-template-columns: repeat(2, 1fr)
  }
}

.form__row {
  margin-bottom: 16px;
  margin-bottom: 1rem
}

@media screen and (min-width: 37.5rem) {

  .form__row {
    /* Tablet Portrait / 37.5rem / 600px */
    flex: 1 0 calc(50% - 1rem)
  }

  @supports(display: grid) {

    .form__row {
      margin-bottom: 0
    }
  }
}

.form__row+.form__row {
  margin-top: 16px;
  margin-top: 1rem
}

@supports (display: grid) {

  .form__row+.form__row {
    margin-top: 0
  }
}

.form__row_double {
  flex: 1 0 100%
}

@media screen and (min-width: 37.5rem) {

  .form__row_double {
    /* Tablet Portrait / 37.5rem / 600px */
    display: flex;
    grid-column: span 2
  }
}

@media screen and (min-width: 37.5rem) {

  .form__row_double div {
    /* Tablet Portrait / 37.5rem / 600px */
    max-width: 30ch;
    width: 50%
  }
}

.form__row_double div+div {
  margin-top: 16px;
  margin-top: 1rem
}

@media screen and (min-width: 37.5rem) {

  .form__row_double div+div {
    /* Tablet Portrait / 37.5rem / 600px */
    margin-left: 1rem;
    margin-top: 0
  }
}

.form__row_cta {
  flex: 1 0 100%;
  text-align: center
}

@media screen and (min-width: 37.5rem) {

  .form__row_cta {
    /* Tablet Portrait / 37.5rem / 600px */
    grid-column: span 2
  }
}

.form__input {
  border: 1.04px solid hsl(0, 0%, 60%);
  border: 0.065rem solid hsl(0, 0%, 60%);
  border-radius: 0.25em;
  background: hsl(0, 0%, 90%);
  max-width: 30ch;
  padding: 4px;
  padding: 0.25rem;
  width: 100%;
}

.form__input_checkbox {
  transform: scale(1.5);
}

.form__checkbox {
  display: flex;
  flex: 1 0 100%;
  margin: 16px 0;
  margin: 1rem 0
}

@media screen and (min-width: 37.5rem) {

  .form__checkbox {
    /* Tablet Portrait / 37.5rem / 600px */
    align-content: center;
    justify-content: center;
    grid-column: span 2
  }
}

.form__label_checkbox {
  margin-left: calc(1rem * 0.5);
  margin-top: 0;
}

.form__success {
  color: darkgreen;
  font-size: 0.75em;
  margin: 0.5em 0 0;
}

.form__error {
  color: darkred;
  font-size: 0.75em;
  margin: 0.5em 0 0;
}

.form__button {
  background: hsl(238.2, 51.8%, 37.5%);
  color: white;
  cursor: pointer;
  font-weight: 700;
  min-width: 132px;
  min-width: 8.25rem;
  padding: 0.75em 1em 0.5em
}

.form__button:hover,
.form__button:focus {
  background: hsl(238.2, 51.8%, 55%);
  padding: 0.75em 1em 0.5em;
}

.form__button[disabled] {
  background: hsl(238.2, 51.8%, 37.5%);
  cursor: not-allowed;
  opacity: 0.5;
}

.full-width {
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  position: relative;
  right: 50%;
  width: 100vw;
}

.list_unordered,
.list_bulleted,
.list_ordered,
.list_numbered {
  padding: 0 0 0 2em;
}

.list_unordered li,
.list_bulleted li,
.list_ordered li,
.list_numbered li {
  display: list-item;
}

.list_unordered,
.list_bulleted {
  list-style: disc;
}

.list_unordered ul,
.list_bulleted ul {
  list-style: square;
}

.list_unordered ul ul,
.list_bulleted ul ul {
  list-style: circle;
}

.list_ordered,
.list_numbered {
  list-style: decimal;
}

.list_ordered ol,
.list_numbered ol {
  list-style: lower-alpha;
}

.list_ordered ol ol,
.list_numbered ol ol {
  list-style: lower-roman;
}

.masthead {
  padding: 16px;
  padding: 1rem;
  text-align: center;
}

.masthead__title {
  color: hsl(238.2, 51.8%, 37.5%);
  font-family: 'Sign Painter', script;
  font-size: 32px;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.1;
  margin: 0;
}

.masthead__description {
  color: hsl(238.2, 51.8%, 37.5%);
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 32px;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.1;
  margin: 0;
  text-transform: uppercase
}

@media screen and (min-width: 37.5rem) {

  .masthead__description {
    /* Tablet Portrait / 37.5rem / 600px */
    font-size: 2.5rem
  }
}

.pad {
  padding: 16px;
  padding: 1rem;
}

.pad-top {
  padding-top: 16px;
  padding-top: 1rem;
}

.pad-bottom {
  padding-bottom: 16px;
  padding-bottom: 1rem;
}

.pad-y {
  padding-top: 16px;
  padding-top: 1rem;
  padding-bottom: 16px;
  padding-bottom: 1rem;
}

.pad-left {
  padding-left: 16px;
  padding-left: 1rem;
}

.pad-right {
  padding-right: 16px;
  padding-right: 1rem;
}

.pad-x {
  padding-left: 16px;
  padding-left: 1rem;
  padding-right: 16px;
  padding-right: 1rem;
}

.pad-2 {
  padding: calc(2 * 1rem);
}

.pad-top-2 {
  padding-top: calc(2 * 1rem);
}

.pad-bottom-2 {
  padding-bottom: calc(2 * 1rem);
}

.pad-y-2 {
  padding-top: calc(2 * 1rem);
  padding-bottom: calc(2 * 1rem);
}

.pad-left-2 {
  padding-left: calc(2 * 1rem);
}

.pad-right-2 {
  padding-right: calc(2 * 1rem);
}

.pad-x-2 {
  padding-left: calc(2 * 1rem);
  padding-right: calc(2 * 1rem);
}

.small {
  font-size: 0.75em;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

.thank-you {
  border: 0.5em solid hsl(0, 0%, 0%);
  padding: 16px;
  padding: 1rem;
  text-align: center;
}

.thank-you__row {
  margin: calc(1rem * 2) 0;
}

.thank-you__headline {
  font-size: 2em;
}

.thank-you__button {
  border-radius: 0.25em;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  padding: 0.75em 1em 0.5em;
  text-decoration: none
}

.thank-you__button:link,
.thank-you__button:visited,
.thank-you__button:active {
  background: hsl(238.2, 51.8%, 37.5%);
  color: hsl(0, 0%, 100%);
}

.thank-you__button:hover,
.thank-you__button:focus {
  background: hsl(238.2, 51.8%, 55%);
  color: hsl(0, 0%, 100%);
}

.toggle {
  margin: 4px 0;
  margin: 0.25rem 0;
  display: inline-flex;
  align-items: center;
}

.toggle__switch {
  margin: 0 8px 0 0;
  margin: 0 0.5rem 0 0;
  cursor: pointer;
  position: relative
}

.toggle__switch::before,
.toggle__switch::after {
  background: hsl(0, 0%, 70%);
  content: '';
  display: block;
  transition: all 100ms cubic-bezier(0.4, 0.0, 0.2, 1);
}

.toggle__switch::before {
  border-radius: 0.65em;
  height: 1.3em;
  opacity: 0.6;
  width: 3em;
}

.toggle__switch::after {
  border-radius: 50%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), 0 0 2px rgba(0, 0, 0, 0.4);
  height: 1.7em;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 1.7em;
}

.toggle [type=checkbox] {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

[type=checkbox] {

  /* Focused */

}

[type=checkbox]:focus+.toggle__switch {
  outline: hsl(240, 100%, 50%) solid 1px;
  box-shadow: 0 0 0.5em hsl(240, 100%, 50%);
}

[type=checkbox] {

  /* Disabled */

}

[type=checkbox]:disabled+.toggle__switch {
  opacity: 0.6;
  cursor: not-allowed;
  filter: grayscale(100%)
}

[type=checkbox]:disabled+.toggle__switch::after {
  box-shadow: none;
}

[type=checkbox] {

  /* Checked */

}

[type=checkbox]:checked+.toggle__switch::before {
  background: hsl(240, 100%, 50%);
}

[type=checkbox]:checked+.toggle__switch::after {
  background: hsl(240, 100%, 50%);
  transform: translate(calc(3em - 100%), -50%);
}

.visually-hidden {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}
/*# sourceMappingURL=bundle.css.map */
