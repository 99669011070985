q {
	font-style: italic;
	quotes: "“" "”" "‘" "’";

  &:before {
  	content: open-quote;
  }

  &:after {
  	content: close-quote;
  }
}