button {
  appearance: none;
  background-color: transparent;
	border: none;
	cursor: pointer;
  font-family: inherit;
  font-size: 100%;
	line-height: 1;
  margin: 0;
	overflow: visible;
	padding: 0.25rem;
	text-transform: capitalize;
  -webkit-appearance: button;
  transition: all $speed-fast ease;

  &[disabled] {
    color: $light-gray;
    cursor: auto;
  }

  /* Isolate this selector */
  &::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  /* Isolate this selector */
  &:-moz-focusring {
    outline: $border-thinner dotted ButtonText;
  }

}

[type="button"] {
  border: none;
  border-radius: 0.25em;
  padding: 0.25rem;
  text-transform: capitalize;
  transition: box-shadow $speed-fast ease;
  -webkit-appearance: button;
}

[type="reset"],
[type="submit"] {
  border-radius: 0.25em;
  -webkit-appearance: button;
}

/* Isolate this selector */
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  border-style: none;
  outline: $border-thinner dotted ButtonText;
  padding: 0;
}

/* Isolate this selector */
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}