.admin {
  background: #eee;

  &__masthead {
    background: #ccc;
    border-bottom: 0.25em solid $blue;
    padding: $space;

    &-layout {

      @include mq-600 {
        display: grid;
        grid-template-columns: 1fr auto;
      }
    }

    &-title {
      font-size: 1em;
      margin-bottom: 0;

      @include mq-600 {
        padding-top: 0.25em;
      }
    }
  }

  &__nav {
    padding-top: 0.5em;

    @include mq-600 {
      line-height: 1.5;
      padding-top: 0;
    }

    &-list {
      margin-left: -0.5em;
      margin-right: -0.5em;

      &-item {
        display: block;

        @include mq-600 {
          display: inline-block;
        }

        &:first-child .admin__nav-link {
          padding-left: 0;
        }

        &:last-child .admin__nav-link {
          padding-right: 0;
        }

        &_button {
          margin-bottom: 0.5rem;
          margin-top: 0.5rem;

          @include mq-600 {
            margin-bottom: 0;
            margin-top: 0;
          }
        }
      }
    }

    &-link {
      padding-left: 0;
      padding-right: 0;

      @include mq-600 {
        padding-left: 0.5em;
        padding-right: 0.5em;
      }
    }
  }

  &__form {
    border: none;

    &-title {
      font-size: 1.5em;
    }
  }

  &__success {
    color: green;
  }

  &__error {
    color: red;
  }

  &__table {
    font-size: 0.75em;
    margin: 0;
    table-layout: auto;

    th,
    td {
      padding-bottom: 0.25rem;
      padding-top: 0.25rem;

      &:first-child {
        padding-left: $space;
      }
    }

    & + & {
      border-top: 0.25em solid $blue;
    }

  }

  &__message > :last-child {
    margin-bottom: 0;
  }

  &__download-button {
    background: hsl( 240, 100%, 50% );
    border-radius: 0.25em;
    box-shadow: 0 0.125rem 0 0 hsl( 240, 25%, 90% );
    color: hsl( 0, 0%, 100% );
    display: inline-block;
    text-decoration: none;
    text-transform: capitalize;
    transition: box-shadow 200ms ease;

    @include mq-600 {
      margin-right: $space;
    }

    &:link,
    &:visited {
      box-shadow: 0 0.125rem 0 0 hsl( 240, 25%, 90% );
      color: hsl( 0, 0%, 100% );
    }

    &:hover,
    &:focus {
      background: hsl( 220, 100%, 50% );
      box-shadow: 0 0.125rem 0 0 hsl( 240, 25%, 80% );
      color: hsl( 0, 0%, 100% );
      padding: 0.25rem;
    }

    &:active {
      background: hsl( 220, 100%, 60% );
      box-shadow: none;
      color: hsl( 0, 0%, 100% );
      padding: 0.25rem;
    }
  }
}

[data-state="inactive"] {
  display: none;
}

[data-state="success"] p {
  color: green;

  > button {
    margin: 0 $space;
  }
}

[data-state="error"] p {
  color: red;

  > button {
    margin: 0 $space;
  }
}
