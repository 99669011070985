.list {

  &_unordered,
  &_bulleted,
  &_ordered,
  &_numbered {
  	padding: 0 0 0 2em;

    li {
      display: list-item;
    }
  }

  &_unordered,
  &_bulleted {
  	list-style: disc;

    ul {
    	list-style: square;

      ul {
      	list-style: circle;
      }
    }
  }

  &_ordered,
  &_numbered {
  	list-style: decimal;

    ol {
    	list-style: lower-alpha;

      ol {
      	list-style: lower-roman;
      }
    }
  }
}