.thank-you {
  border: 0.5em solid $black;
  padding: $space;
  text-align: center;

  &__row {
    margin: calc($space * 2) 0;
  }

  &__headline {
    font-size: 2em;
  }

  &__button {
    border-radius: 0.25em;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    padding: 0.75em 1em 0.5em;
    text-decoration: none;

    &:link,
    &:visited,
    &:active {
      background: hsl(238.2, 51.8%, 37.5%);
      color: $white;
    }

    &:hover,
    &:focus {
      background: hsl(238.2, 51.8%, 55%);
      color: $white;
    }
  }
}