pre {
	font-family: $font-stack-code;
	font-size: 1rem;
  tab-size: 2;

	/* Browser specific (not valid) styles to make preformatted text wrap */
	white-space: pre-wrap;       /* css-3 */
	white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
	white-space: -pre-wrap;      /* Opera 4-6 */
	white-space: -o-pre-wrap;    /* Opera 7 */
	word-break: break-word;      /* Internet Explorer 5.5+ */
	word-wrap: break-word;       /* Internet Explorer 5.5+ */
	overflow-wrap: break-spaces; /* Internet Explorer 5.5+ */
	overflow-wrap: break-word;   /* Internet Explorer 5.5+ */

	code {
		display: block;
		line-height: 1.2;
	  margin: 0;
		padding: $space;
	}
}