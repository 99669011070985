ul,
ol {
	margin: 0 0 0.7em;
}

dl {
	color: $dark-gray;
  line-height: 1.4;
	margin: 0 0 0.7em;
	padding: 0 0 0 1em;
}

dd {
  margin: 0;
	padding: 0 0 0 2em;

  + dt {
    margin: 1em 0 0;
  }
}

dt {
	font-weight: 700;
}