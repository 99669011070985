textarea {
	border: none;
	border-radius: 0.25em;
	box-shadow: inset 0 0.0625rem 0.0625rem 0.0625rem hsl( 0, 0%, 50% );
	display: block;
  font-size: 100%;
  margin: 0;
  max-width: 100%;
  overflow: auto;
	padding: 0.25rem;
}