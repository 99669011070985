h1 {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.1;
  margin: 0 0 0.5em;
}

h2 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.1;
  margin: 0 0 0.5em;
}

h3 {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.125;
  margin: 0 0 0.6em;
}

h4 {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.125;
  margin: 0 0 0.6em;
}

h5 {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.4;
  margin: 0 0 0.7em;
}

h6 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  margin: 0 0 0.7em;
}

@include mq-1200 {
  h1 {
    font-size: 3rem;
  }

  /*h2 {
    font-size: 2rem;
  }*/

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.25rem;
  }
}