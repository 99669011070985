blockquote {
	margin: calc( $space * 2 );
	text-align: center;

  p {
  	line-height: 1.3;
  	margin-bottom: 0.5em;
  }

  cite {
  	font-weight: 700;
  }
}